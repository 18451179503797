import themeMedia from './theme-media'

themeMedia()

Promise.all(
    [
        import('./ajax-filter'),
        import('./section-slider'),
        import('./contact-form'),
    ]
).then(
    (
        [
            ajaxFilter,
            sectionSlider,
            contactForm,
        ]
    ) => {
        ajaxFilter.default()
        sectionSlider.default()
        contactForm.default()
    }
)