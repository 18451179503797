import PubfaCatLogo from '../img/publifarm-cat-logo.svg'
import IconSearch from '../img/search.svg'

/**
 * Export all the theme images
 */

const themeMedia = () => {
    newImage(PubfaCatLogo)
    newImage(IconSearch)
}

/**
 * Compile image from src folder
 * 
 * @param Image source 
 */

const newImage = (source) => {
    const newImage = new Image()
    newImage.src = source
}

export default themeMedia